#shop-category {
    &.container {
        background-color: #ffffff;
    }
    ion-content {
        --ion-background-color: #ffffff;
    }
    & > .content-container {
        background-color: #ffffff;
        padding: 0rem;

        .searchbar-container {
            padding: 0 1rem;
            padding-top: 1rem;
            > #search-bar {
                margin: 0;
            }
        }

        .shop-profile-container {
            // height: 40vh;
            height: 48vh;
            margin-top: -1rem;
            ion-img {
                height: 100%;
                object-fit: cover;
            }
        }

        .menu-container {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            // height: 100%;
            height: fit-content;
            // padding-bottom: 26rem;
            // margin-top: -1.5rem;
            position: relative;
        }
        .promotion-section {
            padding: 1rem;
            width: 100%;

        }

        #sorting-dropdown {
            padding: 0.5rem 1rem;
        }
    }
}