#gallery-modal {
  &.container {
    border-bottom: 1px solid $border;
    // display: flex;
    // flex: 1;
    align-self: flex-end;
    background: transparent;
    padding: 3vh 1rem;

    & > .modal-wrapper {
      border-radius: 1.5rem;
    }

    & .content-container {
      // height: 50vh;
      background-color: $lightgrey-bg;
      > ion-content {
        --ion-background-color: $lightgrey-bg;
      }
      padding: 1rem 3rem;
      padding-top: 1.5rem;
      // border-radius: 1.5;

      > .modal-container {
        padding: 1rem 1.5rem;
        height: 100%;
      }

      .btn-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .modal-btn {
          flex: 0.48;
          border: 0.3px solid $black;
          height: 2.25rem;
          padding: 8px 7.7px 8.8px 7.7px;
          border-radius: 15rem;
          background-color: #ffffff;
          color: $black;

          &--primary {
            background-color: #000000;
            color: #ffffff;
          }
        }
      }

      .header-container {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin-top: 1rem;

        .header-title {
          color: $black;
          font-size: $normal;
          font-weight: bolder;
        }

        > .btn-full-width {
          margin: 0;
          justify-content: flex-end;
          width: fit-content;
          padding: 0.2rem 1rem;
        }
      }

      .gallery-wrapper {
        height: 65%;
      }
      .gallery-container {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow: scroll;
        &:after {
          content: "";
          flex: auto;
        }
        ion-img {
          // border: 1px solid $border;
          margin-top: 0.55rem;
          margin-left: 0.275rem;
          margin-right: 0.275rem;
          border-radius: 0.75rem;
          overflow: hidden;
          object-fit: cover;
        }
        > div {
          position: relative;
          > ion-icon {
            position: absolute;
            right: 8%;
            top: 14%;
            font-size: 1.5rem;
          }
        }
      }

      .pagination-container {
        // border: 1px solid $red;
        display: flex;
        flex: 1;
        justify-content: center;
        font-size: $small;
        margin: 0.5rem auto 1.5rem auto;
        padding: 0 1rem;
        > .page-text {
          color: $black;
          flex: 0.4;
          text-align: center;
          > span {
            margin-left: 0.25rem;
            margin-right: 0.25rem;
          }
        }
        > .img-amount {
          color: $lightgrey-text;
          flex: 0.2;
          text-align: center;
        }
        > .pagination-icon {
          color: $lightgrey-text;
          font-size: $normal;
          flex: 0.2;
        }
      }
    }
  }
}
