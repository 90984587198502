$black: #222222;
$red: #dd1a1a;
$green: #219653;
$border: rgba(0, 0, 0, 0.1);
$dark-grey: #4f4f4f;
$grey: #828282;
$profile-bg: #f6f7fb;
$lightgrey-bg: #fafafa;
$lightgrey-bg-07: rgba(242, 242, 242, 0.7);
$lightgrey-text: #bdbdbd;
$grey-bg: #f2f2f2;
$main-color: #ffd562;
$secondary-color: #fef8ea;
$light-red-bg: #fff4f4;
$border-red: rgba(221, 26, 26, 0.25);
$img-blank: #e2e2e2;
