#upload-receipt {
    &.container {
        background-color: #ffffff;
        > ion-content {
            --ion-background-color: #ffffff;
        }
        padding: 1rem 0rem;
        .header {
            margin-top: -1rem;
            width: 100%;
            height: 3.75rem;
            display: flex;
            justify-content: center;
            align-content: center;
            p {
                font-weight: 700;
            }
        }
        .content-container {
            padding: 0 2.5rem;
            .upload-container {
                width: 16.375rem;
                height: 16.375rem;
                background-color: #000000;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 1rem;
                margin: 1rem auto;
                // padding-top: 1rem;
                overflow: hidden;
                position: relative;
                &.uploaded {
                    background-color: rgba($color: #000000, $alpha: 0.22);
                }
                .outer-circle {
                    margin-top: 1.5rem;
                    width: 3.25rem;
                    height: 3.25rem;
                    background-color: #ffffff30;
                    border-radius: 10rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .inner-circle {
                        width: 2rem;
                        height: 2rem;
                    }
                }
                p {
                    color: #ffffff;
                    font-size: $small;
                    text-align: center;
                }
                .img-receipt {
                    width: 16.375rem;
                    max-width: 100%;
                    height: auto;
                    // height: 16.375rem;
                    object-fit: cover;
                    position: absolute;
                    z-index: -1;
                }
            }
        }
        .btn-container {
            position: absolute;
            width: 100%;
            padding: 0 1.5rem;
            bottom: -1rem;
        }
    }
}