#multi-select-filter {
  &.container {
    // padding: 0.5rem 0;
    display: flex;
    justify-content: start;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    // flex: 1;
    .checkbox {
      display: none;
    }
    .filter-item {
      margin-right: 0.25rem;
      // min-width: 5.625rem;
      height: 1.75rem;
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.5rem;
      // border: 1px solid $border;
      &.checked {
        border: solid 0.5px #000000;
        background-color: rgba(174, 174, 174, 0.1);
      }
    }
    .filter-container {
      display: flex;
      flex-direction: row;
      font-size: $small;
      padding: 0.5rem;
      white-space: nowrap;
      overflow-x: auto;
      max-width: calc(100% + 2rem);
    }
    .icon-container {
      font-size: $medium;
      display: flex;
      flex: 0.1;
      // border: 1px solid $border;
      align-items: center;
      position: relative;
      margin-right: 0.5rem;
      margin-left: 1rem;
      .filter-icon {
        // position: absolute;
      }

      ion-badge {
        position: absolute;
        top: 0.45rem;
        right: -0.2rem;
        opacity: 0.9;
        font-size: $smaller;
        padding: 1px 3px;
        background-color: $black;

        & ~ ion-icon {
          margin-right: 1.2rem;
        }
      }
    }
  }
}
