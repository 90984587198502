#all-promotion {
    &.content-container {
        background-color: $lightgrey-bg;
        > ion-content {
            --ion-background-color: $lightgrey-bg-07;
        }
        // align-self: flex-end;
        // background: transparent;
        padding: 0rem;
        // padding-top: 10vh;

        .menu-container {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            // padding-top: 1rem;
            // border-top-left-radius: 1rem;
            // border-top-right-radius: 1rem;
            height: 100%;
            // height: fit-content;
            padding-bottom: 6rem;
            // position: absolute;
            // bottom: 0;
        }
        .promotion-header {
            text-align: center;
            font-size: $medium;
            font-weight: 700;
        }
    }
}