#porto-all-shop {
    &.porto-page-ctn {
        background-color: $lightgrey-bg;
        >ion-content {
            // --ion-background-color: #dadada;
            // background-color:red;
            // padding: 0;
            padding: 0 1rem;
        }
        // padding: 0rem;
       .content{
        padding: 0 1rem;

       }
        .slide-container {
            display: flex;
            flex-direction: column;
            flex: 0.5;
            .indicators {
                padding: 0;
                // color: #E2491E;
                // background-color: #E2491E;
            }
        }

        .indicator {
            width: 0.5rem;
            height: 0.5rem;
            margin: 0 0.25rem;
            border-radius: 10rem;
            background-color: #f59277;
            &.active {
                width: 1.125rem;
                height: 0.5rem;
                margin: 0 0.25rem 0 0;
                border-radius: 1rem;
                background-color: #E2491E;
            }
        }

        .shop-profile-container {
            // width: 23.438rem;
            // height: 100%;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // margin-top: -0.5rem;
            ion-img {
                // height: 100%;
                object-fit: cover;
            }
            div.images-wrap > div:not(.active) {
                // border: solid 0.5px $red;
                // margin-left: -4rem;
            }
            div.images-wrap > div.active {
                // border: solid 0.5px $red;
                // margin-right: -4rem;
            }
            .collection-img-container {
                // background: bisque;
                // border: 1px solid $border;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 0 0.5rem;
                // box-shadow:0px 3px 6px 7px #c9e8fc;

                .collection-header {
                    font-size: $medium;
                    font-weight: 700;
                    margin: 0.75rem 0;
                }
                .collection-img {
                    border-radius: 0.75rem;
                    object-fit: cover; 
                    // height: 11.875rem;
                    // width: 21.5rem;
                    // box-shadow:12px 12px 0px 0px #c9e8fc;

                }
            }
        }

        .menu-container {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            // height: 100%;
            height: fit-content;
            padding-bottom: 2rem;
            // margin-top: -1.5rem;
            position: relative;
            z-index: 3;
        }

        .order-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            .btn-container {
                // margin: 0;
            }
            .cart-add {
                width: 4.938rem;
                height: 2.813rem;
                margin: 0 1.25rem 0 0;
                padding: 0.438rem 1.438rem 0.375rem 1.5rem;
                border-radius: 10rem;
                border: solid 0.5px $border;
                background-color: #ffffff;              
                .cart-icon {
                    width: 1.75rem;
                }
            }
        }

        .share-shop-container {
            width: 5rem;
            height: 1.5rem;
            padding: 0.188rem 1.125rem 0.188rem 1.125rem;
            border-radius: 1rem;
            border: solid 0.5px #f1be30;
            background-color: rgba(241, 190, 48, 0.1);
            color: #f1be30;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .share-icon {
                // font-size: 5rem;  
                margin-right: 0.5rem;
            }
            p {
                font-size: $small;
                margin: 0;
            }
        }
        .profile-container {
            font-size: $small;
            height: 2.5rem;
            align-items: center;
            justify-content: center;
            align-content: center;
            display: flex;
            margin-right: 1rem;
            .shop-img {
                 // border: 1px solid $border;
                height: 100%;
                width: 2.5rem;
                background-size: contain;
                background-repeat: no-repeat;
                align-self: center;
                border-radius: 10rem;
                
                &-blank {
                    background-color: $profile-bg;
                    height: 100%;
                    width: 2.5rem;
                    border-radius: 10rem;
                }
            }
        }
        .all-content-ctn{
            // background-color: #f1be30;
            padding: 0 1rem;
        }
        .page-navigation{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.7rem;
            
        }
        .header-bg{
            height:5rem;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .center-justi{
            display: flex;
            justify-content: center;
            .banner-pic{
                margin-top: 1rem;
                box-shadow:4px 5px 0px 0px #c9e8fc;
                border-radius: 1rem;
            }
            .main-txt{
                font-size: 1.25rem;
                font-weight: 600;
                letter-spacing: 1px;
                margin-top: 0.5rem;
            }
        }
        .shop-list-ctn{
            padding: 0.5rem 0;
            // background-color: aquamarine;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            .shop-list-item{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;    
                .pic{
                    width: 3.621rem;
                    height: 3.621rem;
                    object-fit: none; 
                    border-radius: 3rem;
                    background-color: #ffffff;
                    border: 0.5px solid rgba(0, 0, 0, 0.2);
                    box-shadow:4px 3px 0px 0px #e3f4ff;                    
                } 
                .shop-txt{
                    margin-top: 0.3rem;
                    font-size: 0.75rem;
                    font-weight: normal;
                    letter-spacing: 0.48px;
                    color: #000000;
                }           
            }
            
        }
        



        
    }
   
}