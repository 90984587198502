// @use 'abstracts' as *;

#input-radio-item {
  &.container {
    // border: 1px solid $border;
    display: flex;
    flex: 1;
    color: $black;
    margin-bottom: 0.5rem;
    flex-direction: column;
    height: fit-content;

    .radio-item-container {
      display: flex;
      & > .title-text {
        // border: 1px solid $border;
        font-size: $small;
        flex: 1;
        width: 100%;
      }

      .input-desc {
        font-size: $smaller;
        color: $grey;
        margin-top: -0.75rem;
        // border: 1px solid $border;
      }
    }

    .input-container {
      // border: 1px solid $border;
      font-size: $normal;
      display: flex;
      flex: 1;
      text-align: left;
      justify-content: flex-end;
      align-items: center;
      background-color: $lightgrey-bg;
      margin-left: 1.5rem;
      border-radius: 1rem;
      width: 19.75rem;
      height: 3.75rem;
      padding: 0.5rem 1rem;

      .input-placeholder {
        font-size: $small;
        color: $black;
        margin-right: 0.2rem;
        background-color: #ffffff;
        border-radius: 1rem;
        border: 1px solid $border;
        text-align: center;
        > .rate-unit {
          margin-left: 2rem;
          margin-right: 1rem;
          font-size: $small;
          color: $lightgrey-text;
        }
      }
      & > .right-icon {
        // border: 1px solid $border;
        font-size: $medium;
        color: $black;

        &.editable {
          padding-bottom: 0.1rem;
          border-bottom: 1px solid $black;
        }
      }
      .input-title-text {
        // border: 1px solid $border;
        font-size: $small;
        flex: 1;
        width: 100%;
        color: $lightgrey-text;
      }
      .required {
        color: $red;
        font-size: $small;
        margin-left: 0.25rem;
      }
    }

    ion-radio.radio {
      width: 1.25rem;
      height: 1.25rem;
      border: 1px solid $main-color;
      border-radius: 10rem;
      margin: auto 0.5rem;
      padding: 0.1rem;
      &::part(container) {
        background-color: transparent;
        border-radius: 10rem;
      }
      &::part(mark) {
        border-color: $main-color;
      }

      &.radio-checked::part(container) {
        background-color: $main-color;
        border-radius: 10rem;
      }
    }
  }
}
