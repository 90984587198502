#preview-image-modal {
  &.container {
    // border-bottom: 1px solid $border;
    // display: flex;
    // flex: 1;
    align-self: flex-end;
    background: transparent;
    // padding: 3vh 1rem;

    & > .modal-wrapper {
      // border-radius: 1.5rem;
    }

    & .content-container {
      // height: 50vh;
      background-color: $black;
      > ion-content {
        --ion-background-color: $black;
          overflow: hidden;
          --overflow: hidden;
      }
      padding: 1rem 3rem;
      padding-top: 1.5rem;
      // border-radius: 1.5;

      > .modal-container {
        padding: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
        background-color: #000000;
        border-radius: 0;
      }

      .image-container {
        display: flex;
        width: 100%;

        ion-img {
          // border: 1px solid $border;
          margin: 0rem;
          border-radius: 0.75rem;
          overflow: hidden;
          width: 100%;
          height: auto;
        }
        > div {
          position: relative;
          > ion-icon {
            position: absolute;
            right: 8%;
            top: 14%;
            font-size: 1.5rem;
          }
        }
      }

      .pagination-container {
        // border: 1px solid $red;
        display: flex;
        // flex: 1;
        justify-content: center;
        font-size: $small;
        margin: 1.5rem auto;
        padding: 0 1rem;
        width: 70%;

        > .page-text {
          color: #ffffff;
          flex: 0.4;
          text-align: center;
          > span {
            margin-left: 0.25rem;
            margin-right: 0.25rem;
          }
        }
        > .img-amount {
          color: $lightgrey-text;
          flex: 0.2;
          text-align: center;
        }
        > .pagination-icon {
          color: $lightgrey-text;
          font-size: $normal;
          flex: 0.2;
        }
      }
      .close-icon {
        color: white;
        font-size: 2rem;
        position: absolute;
        top: 2%;
        right: 2%;
        opacity: 0.3;
      }
    }
  }
}
