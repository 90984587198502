#variant-item {
  &.container {
    height: 1.875rem;
    width: 6.375rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin: 0.25rem 0.25rem;

    &--half {
      width: 13.25rem;

      > .variant-item-container {
        > .title-container {
          flex: 0.75;
          justify-content: flex-end;
        }
        > .icon-container {
          flex: 0.25;
          // border: 1px solid $border;
          justify-content: flex-end;
          padding-right: 1rem;
        }
      }
    }

    .variant-item-container {
      display: flex;
      flex: 1;
      flex-direction: row;
      color: $grey;
      align-items: center;
      // margin-bottom: 1rem;
      border-radius: 10rem;
      background-color: $lightgrey-bg;
      justify-content: center;
      align-items: center;
      border: 1px solid $border;
      height: 100%;
      width: 100%;
    }

    & .title-container {
      // border: 1px solid $border;
      font-size: $small;
      align-items: center;
      justify-content: center;
      align-content: center;
      display: flex;

      flex: 0.7;
    }

    & .title-text {
      // border: 1px solid $border;
      font-size: $small;
    }

    & .icon-container {
      // border: 1px solid $border;
      font-size: $normal;
      display: flex;
      flex: 0.3;
      height: 100%;
      text-align: right;
      justify-content: flex-start;
      align-items: center;

      & > .icon {
        font-size: $medium;
        color: $grey;
      }
    }
  }
}
