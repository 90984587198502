#order-card{
    &.container {
      display: flex;
      flex-direction: column;
      border: 0.5px solid rgba(0, 0, 0, 0.1);
      
      border-radius: 16px;
      padding: 1rem;
      // width: 100%;
      width: 90%;
      margin-left: 1rem;
      min-width: 15rem;
      margin-bottom: 1rem;
    //   max-width: 18rem;
      min-height: 18.25rem;
        .order-status-ctn {
            display: flex;
            align-items: center;
            .status-color {
                width: 0.4rem;
                height: 0.4rem;
                background-color: red;
                border-radius: 15px;
                &.red {
                    background-color: $red;
                }
                &.green {
                    background-color: $green;
                }
                &.grey {
                    background-color: $dark-grey;
                }
                &.lightgrey {
                    background-color: $lightgrey-text;
                }
            }
            .order-status{
                background-color: black;
                color: white;
                width: 6.5rem;
                height: 1.625rem;
                padding: 0.25rem 0 0.313rem;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 0.5rem;
            }
        }
      

      .payment-list-show {
        width: fit-content;
        margin-left: 1rem;
        // margin-bottom: 1rem;
        padding-top: 1rem;
        display: flex;
        // flex: 1 1;
        // flex-grow: 1;
        // flex-shrink: 1;
        // flex-basis: 0%;
        // flex-direction: row;
        // white-space: nowrap;
        // overflow-x: auto;
        // max-width: calc(100% + 2rem);

        .product-pic{
            width: 6rem;
            height: 6rem;
            object-fit: cover;
            margin-right: 0.75rem;
            border-radius: 0.5rem;
            overflow: hidden;
            // height: 100%;
        }
        .product-detail{
            // margin-left: 1rem;
            display: flex;
            flex-direction: column;
            // justify-items: center;
            justify-content: center;
            flex: 4;
        }
        .product-price{
            display: flex;
            flex-direction: column;
            align-items: flex-end;

        }


      }

      .date-ctn{
        display: flex;
        justify-content: flex-end;
      }
      .order-detail{
          margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;
      }
      .date-text{
            color: #4f4f4f;
            font-size: $small;
      }
      .price{
          font-size: $light;
          font-weight: bold;
      }

      .payment-type{
        font-size: 0.875rem;
        font-weight: 500;
        // color: grey;
    }
        .header {
            // margin: 1rem 0;
            font-size: $small;
            font-weight: bold;
        }
        .sub-header {
            margin: 1rem 0;
            font-size: $small;
            font-weight: bold;
        }
        .ava-ctn {
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem;
        }
        .div-style {
            margin-top: 0.5rem;
        }
        .name-ctn {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-left: 0.5rem;
            > span {
                &:first-child {
                    font-size: $small;
                }
                &:nth-child(2) {
                    font-size: $smaller;
                }
            }
        }
        .desc-text {
            font-size: $smaller;
            color: $grey;
        }
        .bt-text {
            font-size: $small;
            color: $black;
            margin: 0.25rem 0;
            &--secondary {
                color: $lightgrey-text;
            }
        }
        .desc-ctn {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
        }
        .print-btn {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
     
    }
  }
  