// @use 'abstracts' as *;

#modal-input-item {
  &.container {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 3rem;
    // border: 1px solid $border;
    color: $black;
    align-items: center;
    border: none;
    // margin-bottom: 1rem;
    margin-bottom: 1.5rem;

    &--multiple {
      flex-direction: column;
      height: fit-content;
    }

    & > .input-title-text {
      // border: 1px solid $border;
      font-size: $small;
      flex: 0.4;
      
      &--multiple {
        width: 100%;
      }
    }

    .input-desc {
      font-size: $smaller;
      color: $grey;
      margin-top: -0.75rem;
      // border: 1px solid $border;
    }

    & > .input-container {
      border: 1px solid $border;
      font-size: $normal;
      display: flex;
      flex: 0.6;
      height: 100%;
      text-align: center;
      justify-content: center;
      align-items: center;
      // border-radius: 0.5rem;
      border-radius: 0.875rem;
      
      & > .input-placeholder {
        font-size: $small;
        color: $grey;
        margin-right: 0.2rem;
        // border: 1px solid $border;
        &.select {
          flex: 1;
          padding-left: 0;
        }
      }
      & > .right-icon {
        // border: 1px solid $border;
        font-size: $medium;
        color: $black;

        &.editable {
          padding-bottom: 0.1rem;
          border-bottom: 1px solid $black;
        }
      }

      &--multiple {
        // border-bottom: 1px solid $red;
        flex: 1;
        width: 100%;
        text-align: left;
      }

      &--fullWidth {
        text-align: left;
        flex: 1;
        width: 100%;
        padding: 1rem;
      }

      &--half-width {
        flex: 0.1;
        text-align: left;
        width: 100%;
        padding: 1rem;
      }
    }

    .required {
      color: $red;
      font-size: $small;
      margin-left: 0.25rem;
    }
  }
}
