#product-option-modal {
  &.container {
    border-bottom: 1px solid $border;
    // display: flex;
    // flex: 1;
    align-self: flex-end;
    background: transparent;
    padding-top: 42vh;

    & > .modal-wrapper {
      border-radius: 1.5rem;
    }

    & .content-container {
      // height: 50vh;
      background-color: $lightgrey-bg;
      > ion-content {
        --ion-background-color: $lightgrey-bg;
      }
      padding: 1rem 0rem;
      padding-top: 1.5rem;
      // border-radius: 1.5;

      .bordered {
        border: none;
        border-bottom: 1px solid $border;
        height: 1px;
        margin-top: 0.5rem;
        margin-bottom: 0;
        align-self: center;
        margin-left: 1rem;
        margin-right: 1rem;
      }
      .social-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1rem;
        align-self: center;
        width: 17.875rem;
        height: 4.375rem;
        padding: 1rem;
        border-radius: $smaller;
        background-color: $lightgrey-bg-07;
        margin-left: auto;
        margin-right: auto;

        .social-icon {
          flex: 0.25;
          font-size: 2.8rem;
          color: $black;
          &--ion {
            flex: 0.25;
            font-size: 2.25rem;
            color: $black;
          }
        }
      }
    }

    .modal-container {
      padding-top: 0;
    }
  }
}
