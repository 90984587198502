#sorting-dropdown {
  &.container {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    color: $black;
    // flex: 1;
    .dropdown-container {
      background-color: $grey-bg;
      border-radius: 10rem;
      padding: 0.25rem;
      font-weight: 700;
      font-size: $small;
      width: fit-content;
      min-width: 7rem;

      ion-select.sorting-select {
        height: 1rem;
        &::part(text) {
          padding-right: 1.5rem;
        }
      }
    }
  }
}
