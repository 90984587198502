#editor-modal {
  &.container {
    border-bottom: 1px solid $border;
    // display: flex;
    // flex: 1;
    align-self: flex-end;
    background: transparent;
    padding-top: 47.5vh;

    & > .modal-wrapper {
      border-radius: 1.5rem;
    }

    & .content-container {
      // height: 50vh;
      background-color: $lightgrey-bg;
      > ion-content {
        --ion-background-color: $lightgrey-bg;
      }
      padding: 1rem 0rem;
      padding-top: 1.5rem;
      // border-radius: 1.5;

      .bordered {
        border: none;
        border-bottom: 1px solid $border;
        height: 1px;
        margin-top: -0.625rem;
        align-self: center;
        margin-left: 1rem;
        margin-right: 1rem;
      }
      .btn-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1.25rem;

        .modal-btn {
          flex: 0.48;
          border: 0.3px solid $black;
          height: 2.25rem;
          padding: 8px 7.7px 8.8px 7.7px;
          border-radius: 15rem;
          background-color: #ffffff;
          color: $black;

          &--primary {
            background-color: #000000;
            color: #ffffff;
          }
        }
      }
    }
  }
}
