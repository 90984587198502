// @use 'abstracts' as *;

#price-billing {
  &.container {
    display: flex;
    flex: 0.8;
    flex-direction: row;
    // justify-content: center;
    // height: 3.75rem;
    // border: 1px solid $border;
    color: $black;
    align-items: center;
    // min-height: 3.75rem;
    height: auto;
    &.padding {
      padding: 1rem 0;  
    }
    &.small {
      // min-height: 2rem;
      // height: auto;
    }

    & > .title-icon--container {
      // border: 1px solid $black;
      display: flex;
      flex: 0.4;
      height: 100%;
      text-align: right;
      justify-content: flex-end;
      align-items: center;
      margin-right: -0.1rem;

      & > .title-icon {
        font-size: 1.625rem;
        &.secondary {
          font-size: $medium;
        }
        &.small {
          font-size: $small;
          font-weight: 500;
        }
      }
    }

    & > .title-text {
      font-size: $normal;
      font-weight: bold;
      line-height: 26px;
      letter-spacing: 0.7px;
      flex: 1;
      &.secondary {
        font-size: $light;
      }
      &.small {
        font-size: $small;
        font-weight: 500;
      }
    }
    .title-desc {
      font-size: $normal;
      font-weight: bold;
      // color: $grey;
      // color: $lightgrey-text;
      margin-right: 0.2rem;
      // border: 1px solid $border;
      margin-top: 0;
      margin-bottom: 0;
      &.secondary {
        font-size: $light;
        font-weight: normal;
      }
      &.small {
        font-size: $small;
        font-weight: normal;
      }
      &.highlight {
        color: $main-color;
      }
    }

    .qty-container {
      display: flex;
      flex-direction: row;
      // justify-content: center;
      align-items: center;
      
      .addIcon {
        font-size: $normal;
        background-color: $grey-bg;
        padding: 0.5rem;
        border-radius: 10rem;
        
      }
      .removeIcon {
        font-size: $normal;
        background-color: $grey-bg;
        padding: 0.5rem;
        border-radius: 10rem;
      }
      .quantity-text {
        font-size: $small;
        font-weight: 700;
        margin: 0 1rem;
      }
    }
    
  }

  .container a {
    text-decoration: none;
  }
}
