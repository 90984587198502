#product-card {
  &.container {
    display: flex;
    flex-direction: column;
    color: $black;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    // border: solid 1px $border;
    // min-width: 9.375rem;
    // width: 9.375rem;
    min-width: 9.175rem;
    width: 9.175rem;
    height: 13.875rem;
    padding: 0 0 0.938rem;
    border-radius: 1.25rem;
    background-color: #ffffff;
    overflow: hidden;
    
    strong {
      font-size: $small;
    }

    .divider--line {
      border-color: $border-red;
      margin: 0.4rem 0;
    }
    
    p {
      font-size: $small;
      margin: 0;
      &.price {
        font-size: $small;
      }
    }
    
    a {
      text-decoration: none;
    }

    .image-container {
      // flex: 0.25;
      width: 100%;
      height: 9.375rem;
      margin: 0 0 0.688rem;
      background-color: #ededed;
      position: relative;
      .product-image {
        object-fit: cover;
        height: 100%;
      }
    }
    .cart-icon-container {
      // flex: 0.25;
      width: 2rem;
      height: 2rem;
      padding: 0.438rem 0.455rem 0.392rem 0.375rem;
      object-fit: contain;
      background-color: $black;
      color: white;
      border-radius: 10rem;
      position: absolute;
      right: 0.5rem;
      bottom: -0.5rem;
      -webkit-box-shadow: 1px 1px 8px 1px rgba(0,0,0,0.23); 
      box-shadow: 1px 1px 8px 1px rgba(0,0,0,0.23);
      .cart-icon {
        font-size: $medium;
      }
    }
    .content {
      flex: 0.75;
      padding: 0 0.875rem;
    }
  }
}
