#product-list {
  &.container {
    & .content-container {
      // height: 50vh;
      // background-color: $lightgrey-bg;
      > ion-content {
        // --ion-background-color: $lightgrey-bg;
      }
      padding: 1rem 0.5rem;
      padding-top: 1.5rem;
      // border-radius: 1.5;

      .filter-container {
        // border: 1px solid $red;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        align-content: center;
        // padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin: 0.5rem 0;

        ion-toggle {
          // --background: blue;
          // --background-checked: #ffd562;
          // --handle-background: green;
          // --handle-background-checked: yellow;
          --handle-width: calc(19px - (2px * 2));
          height: 1.1rem;
          width: 2.2rem;
        }

        & > .sku-container {
          // border: 1px solid $border;
          display: flex;
          flex: 0.5;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          > span {
            font-size: $small;
            margin-left: 0.5rem;
          }
        }
        & > .latest-container {
          // border: 1px solid $border;
          display: flex;
          flex: 0.5;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;

          .latest-select {
            width: 65%;
            height: 1.5rem;
            border-radius: 10rem;
            background-color: $grey-bg;
            font-size: $small;
            text-align: center;
            padding-left: 0;
            font-weight: 700;
          }
        }
      }
    }
  }
}
