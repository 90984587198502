#add-to-cart-modal {
  &.container {
    border-bottom: 1px solid $border;
    // display: flex;
    // flex: 1;
    align-self: flex-end;
    background: transparent;
    // padding-top: 30vh;
    padding-top: auto;

    & > .modal-wrapper {
      // border-radius: 1.5rem;
    }

    & .content-container {
      // height: 50vh;
      height: auto;
      background-color: $lightgrey-bg;
      > ion-content {
        --ion-background-color: $lightgrey-bg;
      }
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      padding-bottom: 2rem;
      // border-radius: 1.5;

      .modal-container {
        padding-left: 2rem;
        padding-right: 2rem;
        max-height: 80%;
        overflow: auto;
      }

      .bordered {
        border: none;
        border-bottom: 1px solid $border;
        height: 1px;
        margin-top: -0.625rem;
        align-self: center;
        margin-left: 1rem;
        margin-right: 1rem;
      }
      
      
      .image-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        ion-img {
          margin-right: 2rem;
        }
      }
    }
    .price-text {
      font-size: $normal;
      font-weight: 700;
    }
    .price-container {
      flex: 0.5;
      .price-text {
        font-size: $big;
        font-weight: 700;
      }
    }
    
    .order-container {
      width: 100%;
      // border: 1px solid;
      display: flex;
      flex: 1;
      flex-direction: row;
      height: 100%;
      align-items: center;
      padding-left: 1rem;
      padding-right: 1rem;
      .btn-container {
        align-items: flex-end;
      }
    }
    .btn-container {
      display: flex;
      flex: 0.5;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 1.25rem;
      align-items: center;
      .btn-full-width {
        width: 55%;
      }

      .modal-btn {
        // flex: 0.5;
        border: 0.3px solid $black;
        height: 2.25rem;
        width: fit-content;
        background-color: #ffffff;
        color: $black;
        margin: 0;
        padding: 0.5rem 1rem;
        border-radius: 1rem;

        &.big {
          height: 3rem;
          font-size: $big;
          padding: 0.625rem 0.968rem;
        }

        &--primary {
          background-color: #000000;
          color: #ffffff;
          margin-bottom: 1rem;
        }
      }
    }

    .scroll-container {
      overflow: auto;
      max-height: 70vh;
      overflow-y: scroll;
      /* height: 400px; */
      background: gray;
    }
  }
}
