// @use 'abstracts/colors';
// @use 'components/ListHeader';
// @forward 'abstracts';
// @forward 'components';

/* ----------- Components ----------- */
@import "abstracts/colors";
@import "abstracts/fontSize";
@import "components/ListHeader";
@import "components/PageHeader";
@import "components/PageHeaderCover";
@import "components/SectionHeader";
@import "components/ListItem";
@import "components/CollectionListItem";
@import "components/VariantItem";
@import "components/ProductListItem";
@import "components/MenuItem";
@import "components/InputItem";
@import "components/ModalInputItem";
@import "components/PaymentInputItem";
@import "components/SearchBar";
@import "components/Segment";
@import "components/InputRadioItem";
@import "components/Promotion";
@import "components/ProductCard.scss";
@import "components/CartItem";
@import "components/PriceBilling";
@import "components/SortingDropdown";
@import "components/RadioItem";
@import "components/MultiSelectFilter";
@import "components/ShippingAddress";
@import "components/ShippingProductList";
@import "components/ShippingPayment";
@import "components/OrderCard";

/* -------------- Pages -------------- */
@import "pages/ShopProfile";
@import "pages/ShopInfo";
@import "pages/UserInfo";
@import "pages/Payment";
@import "pages/AddBankAccountModal";
@import "pages/AddPromptPayAccountModal";
@import "pages/ProductList";
@import "pages/ProductOptionModal";
@import "pages/PostOnSocial";
@import "pages/PostOnSocialPage";
@import "pages/ProductDetail";
@import "pages/CreateProduct";
@import "pages/EditorModal";
@import "pages/VariantModal";
@import "pages/SettingSkuModal";
@import "pages/CreateProductOptionModal";
@import "pages/CreateCollectionModal";
@import "pages/GalleryModal";
@import "pages/PaymentMoreModal";
@import "pages/ShippingMethodList";
@import "pages/ShippingMethod";
@import "pages/PreviewImageModal";
@import "pages/ShopOptionModal";
@import "pages/AddToCartModal";
@import "pages/Cart";
@import "pages/ShopPromotion";
@import "pages/AddAddressModal";
@import "pages/AddressModal";
@import "pages/UploadReceipt";
@import "pages/AllPromotion";
@import "pages/ShopCategory";
@import "pages/Collections";
@import "pages/ShippingStatus";
@import "pages/UnpaidStatus";
@import "pages/PaidStatus";
@import "pages/Order";


@import "pages/PortoChinoAllShop";
@import "pages/PortoChinoShopPage";
@import "pages/PortoChinoProductPage";


@mixin with-count($n) {
  @if $n == 1 {
    &:first-child:nth-last-child(1) {
      @content;
    }
  }
  @else {
    &:first-child:nth-last-child(#{$n}),
    &:first-child:nth-last-child(#{$n}) ~ & {
      @content;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

.radio-badge-container {
  display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.none-padding-container {
  width: 100vw;
  margin-left: -1rem;
  margin-right: -1rem;
}
.hr-scroll-container {
    width: fit-content;
    margin-left: -1rem;
    margin-bottom: 2rem;
    padding-right: 1rem;
    // height: 6.75rem;
    display: flex;
    flex: 1;
    flex-direction: row;
    // width: fit-content;
    white-space: nowrap;
    overflow-x: auto;
    max-width: calc(100% + 2rem);
    &:after {
      content: "";
      flex: auto;
    }
}

.content-container {
  padding: 1rem;
}
.list-container {
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  // border: 1px solid $border;
}
.wrap-container {
  // padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  // background-color: #ffffff;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: scroll;
  &:after {
    content: "";
    flex: auto;
  }
  padding: 0 1rem;
  // border: 1px solid $border;
}
.menu-container {
  background-color: #ffffff;
  width: 100%;
  border-radius: 1.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  padding: 0 1rem;

  & > :first-child {
    border-top: none !important;
  }
  > .title {
    margin-bottom: 0;
  }
}
.radio-container {
  background-color: #ffffff;
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
  padding: 1rem;
}

.payment-container {
  background-color: #ffffff;
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
  padding: 1rem 1.5rem;

  & > :first-child {
    border-top: none !important;
  }
}
.modal-container {
  background-color: #ffffff;
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
  padding: 1rem 1.125rem;
}
.divider {
  &--space {
    height: 1rem;
    width: 100%;
    background: transparent;
  }
  &--white {
    height: 1rem;
    width: 100vw;
    background: #fafafa;
  }
  &--line {
    border-top: 0.5px solid rgba(0, 0, 0, 0.1);
    height: 1px;
    width: 100%;
  }
}

ion-toggle {
  // --background: blue;
  --background-checked: #ffd562;
  // --handle-background: green;
  // --handle-background-checked: yellow;
  // --handle-width: calc(19px - (2px * 2));
  // height: 1.1rem;
  // width: 2.2rem;
  width: 3.2rem;
  height: 2rem;
}

ion-select::part(icon) {
  display: none !important;
}

ion-select::part(text) {
  background-image: url("icons/chevron-down-outline.svg");
  background-position: right;
  background-repeat: no-repeat;
}

.modal-header {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 82%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
  p {
    margin-bottom: 0.5rem;
  }
}

.img-gallery--add {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  // border: 1px solid rgba(0, 0, 0, 0.1);

  > .img-placeholder {
    margin: 0.5rem 0.375rem;
  }
}
.img-placeholder {
  width: 6rem;
  max-width: 6rem;
  height: 6rem;
  object-fit: cover;
  overflow: hidden;
  border-radius: 0.5rem;
}
.img-placeholder--md {
  width: 5rem;
  max-width: 5rem;
  height: 5rem;
  object-fit: cover;
  overflow: hidden;
  border-radius: 0.5rem;
}
.img-placeholder--sm {
  width: 4rem;
  max-width: 4rem;
  height: 4rem;
  object-fit: cover;
  overflow: hidden;
  border-radius: 0.5rem;
}

.title {
  font-weight: bold;
  font-size: 0.75rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.caption-area {
  width: 100%;
  // height: 8.75rem;
  margin: 0.5rem 0.375rem;
  padding: 0.75rem;
  border-radius: 1rem;
  background-color: #fafafa;

  p {
    font-size: 0.75rem;
  }
  textarea {
    background-color: #fafafa;
    font-size: 0.75rem;
    width: 100%;
    border: none;
  }
}

.btn-container {
  // border: 1px solid rgba(0, 0, 0, 0.1);
  width: 90%;
  margin: 1rem auto;
  button:disabled {
    opacity: 0.2;
  }
}

.btn-section-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  padding: 0 1rem;

  > hr {
    flex: 0.2; 
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
  }
  > div {
    flex: 0.5;
  }
}

.btn-full-width {
  width: 100%;
  height: 2.25rem;
  margin: 0.5rem auto;
  border-radius: 24px;
  background-color: #000000;
  color: #fafafa;

  &--secondary {
    background-color: #ffffff;
    color: $black;
    border: 0.3px solid #000000;
  }
 
  & > .icon {
    // border: 1px solid rgb(255, 255, 255);
    font-size: 1rem;
    vertical-align: middle;
    margin-right: 0.5rem;
  }
  &.big {
    height: 2.813rem;
    font-size: 0.875rem;
  }
}

.editor-container {
  font-size: 0.75rem;
  color: #bdbdbd;
}

.editor-wrapper {
  min-height: 4rem;
}

.popover-custom .popover-content {
  // background: #222;
  // --background: #444;
  // --height: 5vh;
  // border-bottom: 1px solid #ece9e9;

  > .popover-viewport > ion-item {
    font-size: 1rem;

    &.item-lines-full {
      --border-color: rgba(0, 0, 0, 0.1);
    }
    &::part(native) {
      justify-content: center;
      align-items: center;
    }
  }
}


.billing-bottom-bar {
  width: 100%;
  height: fit-content;
  margin: 2.875rem 0 0 0;
  padding: 1rem 1rem;
  // box-shadow: 0 1px 25px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.05);
  border: solid 0.3px #ffffff;
  background-color: #ffffff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  display: flex;
  flex: 1;
  flex-direction: column;
  // align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 2;

  & div.btn-container {
    display: flex;
    flex: 1;
    text-align: right;
    justify-content: space-between;
    align-items: flex-end;
    // justify-content: flex-end;
    margin-left: 0;
    margin-right: 0;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
    & > .btn-full-width {
      margin: 0;
      width: 100%;
    }
    // border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .shipping-method-text {
    color: #bdbdbd;
    font-size: 0.75rem;
    font-weight: 700;
  }
}

.tab-bar-step {
  width: 100%;
  height: 5.75rem;
  margin: 2.875rem 0 0 0;
  padding: 1rem 1rem;
  // box-shadow: 0 1px 25px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.05);
  border: solid 0.3px #ffffff;
  background-color: #ffffff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 9999;

  & div.btn-container {
    display: flex;
    flex: 1;
    text-align: right;
    justify-content: space-between;
    align-items: flex-end;
    // justify-content: flex-end;

    & > .btn-full-width {
      margin: 0;
      flex: 0.45;

      @include with-count(1) {
        position: absolute;
        top: 25%;
        right: 1rem;
      }
    }
    // border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.variant-container {
  // border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 0;
  margin: 0.5rem 0;
  margin-bottom: 0.25rem;
  flex-wrap: wrap;
  // justify-content: space-between;
  align-content: space-between;
  border-top: 0.5px solid $border;
}

.border-top-radius-none {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.border-bottom-radius-none {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

ion-item.radio-badge {
  border-radius: 10rem;
  min-width: 5rem;
  width: fit-content;
  height: 1.75rem;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.125rem 0;
  border: solid 0.5px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  &::part(native) {
    background: transparent;  
    padding-left: 0;
  }
  ion-radio {
    width: 0;
    margin: 0.5rem;
    &::part(mark) {
      display: none;
    }
    &::part(container) {
      border-color: transparent;
      border: none;
      border-width: 0;
    }
  }
  ion-label.radio-badge-label {
    margin: 0;
    font-size: 0.75rem;
    text-align: center;
  }
  &.item-radio-checked {
    // background-color: #000000;
    background-color: #828282;
    color: #fafafa;
    border: none;
  }
}

.editor-class.description {
	font-size: 0.75rem;
	color: #828282;
  div {
    font-size: 0.75rem !important;
  }
  div[data-contents="true"] > * {
    margin: 0 !important;
  }
}
.public-DraftStyleDefault-block {
  margin: 0 0 1em 0;
}

* {
  font-family: "Prompt";
}
.react-calendar__navigation__arrow{
  color: #000000;
}
.react-calendar__navigation__label{
  color: #000000;
}
.react-calendar__month-view__days__day{
  color: #000000;
}

.tab-bar-btn {
  &[aria-selected="true"] {
    color: #222222;
  }
  ion-icon {
    font-size: 1.3rem;
  }
}
.tab-bar-bottom {
  height: 4.5rem;
  box-shadow: 0px 1px 12px 0 rgba(0, 0, 0, 0.08);
  border: none;
}
