ion-toolbar {
--background: #fff;
--border-color: transparent;
}

:root {
--ion-default-font: Prompt;
font-family: Prompt;
--placeholder-color: #ffffff;
--ion-font-family: Prompt;
}

body {
--ion-default-font: Prompt;
--ion-font-family: Prompt;
font-family: Prompt;
--placeholder-color: #ffffff;
--ion-background-color: #ffffff;
}

html {
--ion-default-font: Prompt;
--ion-font-family: Prompt;
 font-family: Prompt;
}


