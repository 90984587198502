#cart-item {
  &.container {
    height: 6.438rem;
    width: 100%;
    // margin-top: 0.5rem;
    margin-bottom: 1rem;
    // margin-left: -1rem;
    // margin-right: -1rem;
    padding: 0 1rem;
    // border: 1px solid $border;
    border-radius: 0.5rem;

    .img-container {
      // border: 1px solid $border;
      font-size: $small;
      width: 4.5rem;
      height: 4.5rem;
      align-items: center;
      justify-content: center;
      align-content: center;
      display: flex;
      margin-right: 1rem;

      .image {
        // border: 1px solid $red;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: $lightgrey-bg;
        align-self: center;
        border-radius: 0.5rem;
        &-blank {
          background-color: $img-blank;
          width: 100%;
          height: 100%;
          border-radius: 0.5rem;
        }
      }
    }

    .cart-item-container {
      display: flex;
      flex: 1;
      flex-direction: row;
      color: $grey;
      align-items: center;
      // margin: 1rem 0;
      overflow: visible;
      border-radius: 0.5rem;
      background-color: white;
      justify-content: center;
      align-items: center;
      // border: 1px solid $border;
      height: 100%;
      width: 100%;
      ion-item.cart-item {
        border: 1px solid $border;
        border-radius: 0.5rem;
        background-color: white;
        width: 100%;
        // height: 4.5rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem;
        &::part(native) {
          background-color: white;
          border-width: 0;
          padding: 0;
        }
        .item-inner {
          border: none;
        }
      }
      .item-options {
        border: none;
        background-color: transparent;
        .icon-container {
          width: 23%;
          background-color: transparent;
          // border: 1px solid $border;
          font-size: $normal;
          display: flex;
          // flex: 0.3;
          height: 100%;
          text-align: right;
          justify-content: center;
          align-items: center;
    
          & > .icon {
            font-size: $medium;
            color: white;
            background-color: $black;
            border-radius: 10rem;
            padding: 0.5rem;
          }
        }
      }
    }

    .title-container {
      // border: 1px solid $border;
      font-size: $small;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      display: flex;
      flex: 1;
      .price-text {
        font-size: $light;
        font-weight: 700;
      }
    }
    .detail-container {
      // border: 1px solid $border;
      flex: 1;
      height: 100%;
    }

    & .title-text {
      // border: 1px solid $border;
      font-size: $small;
    }

    .variant-wrapper {
      margin-top: -0.25rem;
      .variant-text {
        opacity: 0.8;
        color: $grey;
        font-size: $small;
      }
    }

    .stock-text {
      color: $grey;
      font-size: $smaller;
      margin-top: 0.25rem;
      > span {
        margin-right: 1rem;
      }
    }

    .list-title-text {
      font-size: $small;
    }


    .qty-container {
      display: flex;
      flex-direction: row;
      // justify-content: center;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      
      .addIcon {
        font-size: $normal;
        background-color: $grey-bg;
        padding: 0.5rem;
        border-radius: 10rem;
        
      }
      .removeIcon {
        font-size: $normal;
        background-color: $grey-bg;
        padding: 0.5rem;
        border-radius: 10rem;
      }
      .quantity-text {
        font-size: $small;
        font-weight: 700;
        margin: 0 1rem;
      }
    }
  }
}
