ion-toolbar {
--background: #fff;
--border-color: transparent;
}

:root {
--ion-default-font: Poppins;
font-family: Poppins;
--placeholder-color: #ffffff;
--ion-font-family: Poppins;
}

body {
--ion-default-font: Poppins;
--ion-font-family: Poppins;
font-family: Poppins;
--placeholder-color: #ffffff;
--ion-background-color: #ffffff;
}

html {
--ion-default-font: Poppins;
--ion-font-family: Poppins;
 font-family: Poppins;
}


