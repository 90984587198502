// @use 'abstracts' as *;

#payment-input-item {
  &.container {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 3.5rem;
    margin-left: 2.5rem;
    // border: 1px solid $border;
    color: $black;
    align-items: center;
    // border-top: 0.5px solid $border;
    padding: 1rem 1rem;
    border-radius: 1rem;
    background-color: $lightgrey-bg;
    margin-bottom: 0.5rem;

    &.selected {
      border: 0.5px solid $black;
    }

    &--multiple {
      flex-direction: column;
      height: fit-content;
    }
    .logo{
      height:2.5rem;
      width: 2.5rem;
      margin-right: 1rem;
    }
    .bank-img {
      // flex: 0.5;
      // border: 1px solid $border;
      height: 2rem;
      width: 2rem;
      background-size: cover;
      background-repeat: no-repeat;
      align-self: center;
      border-radius: 10rem;
      margin-right: 0.5rem;
      padding: 0.2rem;
      &-blank {
        background-color: $profile-bg;
        height: 100%;
        width: 2.5rem;
        border-radius: 10rem;
      }
    }

    & > .input-title-text {
      // border: 1px solid $border;
      font-size: $small;
      flex: 0.5;
      
      &--multiple {
        width: 100%;
      }
      &--img {
        color: $black;
      }
    }

    .input-desc {
      font-size: $smaller;
      color: $lightgrey-text;
      margin-top: -0.5rem;
      // border: 1px solid $border;
    }

    & > .input-container {
      // border: 1px solid $border;
      font-size: $normal;
      display: flex;
      flex: 0.5;
      // height: 100%;
      text-align: right;
      justify-content: flex-end;
      align-items: flex-start;
      &--top {
        height: 2.5rem;
      }
      
      & > .input-placeholder {
        font-size: $small;
        color: $lightgrey-text;
        margin-right: 0.2rem;
        // border: 1px solid $border;
        &--read-only {
          color: $lightgrey-text;
        }
      }
      & > .right-icon-container {
        // border: 1px solid $border;
        width: 1rem;
        color: $black;
        border-bottom: 1px solid $black;
        height: 1.2rem;
        & > .right-icon {
          padding-bottom: 0.1rem;
          font-size: $small;
        }
      }

      &--multiple {
        // border-bottom: 1px solid $red;
        flex: 1;
        width: 100%;
        text-align: left;
      }
    }

    
  }
}
