#cart {
    &.content-container {
        background-color: #ffffff;
        > ion-content {
            --ion-background-color: #ffffff;
        }
        padding: 0rem;

        .shop-profile-container {
            // height: 40vh;
            height: 48vh;
            margin-top: -1rem;
            ion-img {
                height: 100%;
                object-fit: cover;
            }
        }

        .menu-container {
            overflow: auto;
            height: 55vh;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            // height: 100%;
            // height: fit-content;
            // padding-bottom: 26rem;
            // margin-top: -1.5rem;
            position: relative;
        }

    }
}