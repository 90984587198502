// @use 'abstracts' as *;

#page-header {
  &.container {
    display: flex;
    // flex: 1;
    flex-direction: row;
    // justify-content: center;
    height: 40px;
    // border: 1px solid $border;
    color: $black;
    align-items: center;
    // margin-top: 1rem;
    margin-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    background-color: #FFFFFF;
    
    &.background {
      background-color: $lightgrey-bg;
    }

    & > .icon-container {
      font-size: $medium;
      line-height: 26px;
      display: flex;
      flex: 0.1;
      height: 100%;
      // border: 1px solid $border;
      align-items: center;
      &--left {
        justify-content: flex-start;
      }
      &--right {
        justify-content: flex-end;
      }
      ion-badge {
        position: absolute;
        top: 0.2rem;
        right: 0.5rem;
        opacity: 0.9;
        font-size: $smaller;
        padding: 3px 5px;
        background-color: $red;

        & ~ ion-icon {
          margin-right: 1.2rem;
        }
      }
      .list-title-icon.add {
        border: 0.14rem solid $black;
        border-radius: 0.3rem;
        font-size: $normal;
        
        &:not(:first-child):last-child {
          margin-left: 1rem;
          border: none;
          font-size: 1.4rem;
        }
      }
    }

    & > .title-text {
      font-size: $small;
      flex: 0.8;
      text-align: center;
    }
  }

  .container a {
    text-decoration: none;
  }
}
