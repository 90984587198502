#my-order {
    &.container {
        background-color: #fff;
        > ion-content {
            --ion-background-color: #fff;
        }
        padding: 0;
        
        .all-contents{           
            // height: 100%;
            height: fit-content;
            // padding-bottom: 2rem;          
            position: relative;           
            display: flex;
            flex-direction: column;
            // align-items: center;
            background-repeat: no-repeat;
            background-size: contain;;
        }
        .status-det {
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            padding-inline: 1rem;
            margin-top: 2rem;

            .main-icon{
                width: 1.25rem;
                height: 1.25rem;
                color: black;
            }
            .search-ctn{
                display: flex;
                justify-content: flex-end;
                // margin-top: 2rem;

            }
            .my-order-ctn{
                display: flex;
                justify-content: center;
                margin-top: 1rem;
                margin-bottom: 2rem;
                .my-order-btn{
                    width: 14.813rem;
                    height: 2.813rem;             
                    border-radius: 10px;
                    border: solid 0.5px black;
                    background-color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0.5rem 0.5rem 0px 0px $secondary-color;
                }
                .order-name-text{
                    margin-left: 0.5rem;
                    font-weight: bold;
                    font-size: 1.2rem;
                    letter-spacing: normal;
                }
            }
        }
        .sw-btn {
            // background-color: red;
        }
        .tab-panel {
            width: 70%;
        }
        .tab {
            padding: 1rem;
            min-width: 6rem;
            height: 3rem;
            width: 6.188rem;
            height: 3.125rem;
            &.MuiTab-textColorInherit.Mui-selected {
                background-color: $lightgrey-bg;
            }
        }
        .status-sec {
            text-align: center;
        }
        .price-label {
            font-size: $small;
        }
        .price-text {
            font-size: $normal;
        }
        & .content-container {
            // height: 50vh;
            // background-color: $lightgrey-bg;
            > ion-content {
                // --ion-background-color: $lightgrey-bg;
            }
            padding: 1rem 0.5rem;
            padding-top: 1.5rem;
            // border-radius: 1.5;

            .filter-container {
                // border: 1px solid $red;
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                align-content: center;
                // padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                margin: 0.5rem 0;

                ion-toggle {
                    // --background: blue;
                    // --background-checked: #ffd562;
                    // --handle-background: green;
                    // --handle-background-checked: yellow;
                    --handle-width: calc(19px - (2px * 2));
                    height: 1.1rem;
                    width: 2.2rem;
                }

                & > .sku-container {
                    // border: 1px solid $border;
                    display: flex;
                    flex: 0.5;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    > span {
                        font-size: $small;
                        margin-left: 0.5rem;
                    }
                }
                & > .latest-container {
                    // border: 1px solid $border;
                    display: flex;
                    flex: 0.5;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;

                    .latest-select {
                        width: 65%;
                        height: 1.5rem;
                        border-radius: 10rem;
                        background-color: $grey-bg;
                        font-size: $small;
                        text-align: center;
                        padding-left: 0;
                        font-weight: 700;
                    }
                }
            }
        }
        .gallery-container {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            justify-content: space-between;
            overflow: scroll;
            &:after {
                content: "";
                flex: auto;
            }
            ion-img {
                // border: 1px solid $border;
                // margin-top: 0.55rem;
                // margin-left: 0.275rem;
                // margin-right: 0.275rem;
                border-radius: 0.75rem;
                overflow: hidden;
                flex: 0 0 31%;
                margin: 0.25rem;
            }
        }
        .sku-text {
            opacity: 0.8;
            color: $grey;
            font-size: $smaller;
        }
        .variant-name {
            color: $black;
            font-size: $small;
        }

        .menu-container.variant-container {
            display: flex;
            flex: 1;
            padding-left: 1rem;
            padding-right: 1rem;
            height: 7rem;
            border: none;

            .variant-img {
                // border: 1px solid $border;
                flex: 0.25;
                align-self: center;
            }
            .variant-detail {
                // border: 1px solid $border;
                flex: 0.75;
                flex-direction: row;
                padding-left: 1rem;
                align-self: center;

                .stock-text {
                    color: $grey;
                    font-size: $small;
                    text-align: center;
                    display: flex;
                    flex-direction: row;
                    margin-top: 1rem;

                    .price-input {
                        border: 1px solid $border;
                        border-radius: 0.5rem;
                        padding: 0.25rem 0.5rem;
                        height: 1.875rem;
                        flex: 0.48;
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }
}
