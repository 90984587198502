// @use 'abstracts' as *;

#product-list-item {
  &.container {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 5rem;
    border: 1px solid $border;
    color: $black;
    align-items: center;
    margin-bottom: 1rem;
    background-color: #ffffff;
    padding: 0.25rem 0.75rem;
    border-radius: 0.5rem;

    & > .list-img-container {
      // border: 1px solid $border;
      font-size: $small;
      height: 3.5rem;
      align-items: center;
      justify-content: center;
      align-content: center;
      display: flex;
      margin-right: 1rem;

      .list-img {
        // border: 1px solid $red;
        height: 100%;
        width: 3.5rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: $lightgrey-bg;
        align-self: center;
        border-radius: 0.5rem;
        &-blank {
          background-color: $profile-bg;
          height: 100%;
          width: 3.5rem;
          border-radius: 0.5rem;
        }
      }
    }

    .detail-container {
      // border: 1px solid $border;
      flex: 0.85;
      height: 100%;
    }

    .sku-text {
      opacity: 0.8;
      color: $grey;
      font-size: $smaller;
    }

    .stock-text {
      color: $grey;
      font-size: $smaller;
      margin-top: 0.25rem;
      > span {
        margin-right: 1rem;
      }
    }

    .list-title-text {
      font-size: $small;
    }

    .list-desc--container {
      // border: 1px solid $border;
      font-size: $normal;
      display: flex;
      flex: 0.15;
      height: 85%;
      text-align: right;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
    }
    .list-desc {
      font-size: $small;
      color: $black;
      margin-bottom: 0.25rem;
    }
    .more-option {
      display: flex;
      width: 3rem;
      height: $big;
      border-radius: $big;
      // border: solid 0.2px $main-color;
      background-color: rgba(255, 213, 98, 0.2);

      justify-content: center;
      align-items: center;
      align-content: center;

      > .icon {
        font-size: $big;
        color: $main-color;
      }
    }
  }
}
