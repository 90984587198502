#shipping-address{
    &.container {
      display: flex;
      flex-direction: column;
      border: 0.5px solid #bdbdbd;
      border-radius: 16px;
      padding: 1rem;
      background-color: white;
      width: 100%;


    //   color: $black;
    //   margin-left: 0.5rem;
    //   margin-right: 0.5rem;
    //   margin-bottom: 1rem;
    //   // border: solid 1px $border;
    //   // min-width: 9.375rem;
    //   // width: 9.375rem;
    //   min-width: 9.175rem;
    //   width: 9.175rem;
    //   height: 13.875rem;
    //   padding: 0 0 0.938rem;
    //   border-radius: 1.25rem;
    //   background-color: #ffffff;
    //   overflow: hidden;
    .header {
        // margin: 1rem 0;
        font-size: 0.875rem;
        font-weight: bold;
    }
    .shipping{
        margin-bottom: 0.5rem;
        font-size: medium;
        font-weight: 700;
    }
    .address-text{
        margin-top: 0.5rem;
        color: #bdbdbd;
        font-size: 0.8rem;
    }
     .shipping-name{
        //  margin-top: -3rem;
         color: #bdbdbd;
         display: flex;
         flex-direction: column;
         align-items: flex-end;
     }
  
     
    }
  }
  