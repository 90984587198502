// @use 'abstracts' as *;

#radio-item {
  &.container {
    // border: 1px solid $border;
    display: flex;
    flex: 1;
    color: $black;
    margin-bottom: 1rem;
    flex-direction: column;
    height: fit-content;

    .radio-item-container {
      display: flex;
      & > .detail-container {
        position: relative;
        display: flex;
        flex: 1;
        width: 100%;
        border-radius: 1.5rem;
        background: #fafafa;
        // border: 1px solid $border;
        padding: 1rem;
        flex-direction: column;
        height: fit-content;
        & > .title-text {
          // border: 1px solid $border;
          font-size: $small;
          margin: 0;
        }
        & > .pen-icon-container {
          // border: 1px solid $border;
          text-align: center;
          width: 1rem;
          color: $black;
          border-bottom: 1px solid $black;
          height: 1.2rem;
          position: absolute;
          right: 1rem;
          top: 0.5rem;
          & > .pen-icon {
            padding-bottom: 0.1rem;
            font-size: $small;
          }
        }
        &-label {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          flex: 1;
          padding-left: 0.75rem;

          > .title-text {
            flex: 0.8;
            font-size: $small;
            color: $black;
            // font-weight: 700;
            height: 100%;
            margin: 0;
            vertical-align: middle;
          }
          > .input-desc { 
            flex: 0.2;
            font-size: $small;
            color: $black;
            text-align: right;
            // font-weight: 700;
            height: 100%;
            margin: 0;
            vertical-align: middle;
          }
        }
      }

      .input-desc {
        font-size: $smaller;
        color: $grey;
        width: 90%;
        height: 100%;
        margin-top: 0.5rem;
        // border: 1px solid $border;
      }
    }
    

    ion-radio.radio {
      width: 1.25rem;
      height: 1.25rem;
      border: 1px solid $main-color;
      border-radius: 10rem;
      margin: auto 0.5rem;
      padding: 0.1rem;
      &::part(container) {
        background-color: transparent;
        border-radius: 10rem;
      }
      &::part(mark) {
        border-color: $main-color;
      }

      &.radio-checked::part(container) {
        background-color: $main-color;
        border-radius: 10rem;
      }
    }
  }
}
