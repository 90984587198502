// @use 'abstracts' as *;

#menu-item {
  &.container {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 3.5rem;
    // border: 1px solid $border;
    color: $black;
    align-items: center;
    border-top: 0.5px solid $border;
    // padding-left: 0.75rem;
    
    & > .list-img-container {
      // border: 1px solid $border;
      font-size: $small;
      height: 2.5rem;
      width: 2.5rem;
      align-items: center;
      justify-content: center;
      align-content: center;
      display: flex;
      margin-right: 1rem;
      &--icon {
        margin-right: 0.375rem;
      }
      &--payment {
        // margin-right: 1.5rem;
        // width: 2rem;
        margin-right: 0;
        justify-content: flex-start;
      }

      .list-img {
        // border: 1px solid $border;
        height: 100%;
        width: 2.5rem;
        background-size: contain;
        background-repeat: no-repeat;
        align-self: center;
        border-radius: 10rem;
        &-blank {
          background-color: $profile-bg;
          height: 100%;
          width: 2.5rem;
          border-radius: 10rem;
        }
      }
      & > .left-icon {
        font-size: $medium;
        color: $black;
      }
    }

    & > .list-title-text {
      // border: 1px solid $border;
      font-size: $small;
      flex: 0.65;
    }

    & > .list-desc--container {
      // border: 1px solid $border;
      font-size: $normal;
      display: flex;
      flex: 0.35;
      height: 100%;
      text-align: right;
      justify-content: flex-end;
      align-items: center;
      
      & > .list-desc {
        font-size: $smaller;
        // color: $grey;
        color: $lightgrey-text;
        margin-right: 0.2rem;
        // border: 1px solid $border;
      }
      & > .right-icon {
        // border: 1px solid $border;
        font-size: $medium;
        color: $black;

        &--big {
          font-size: $big;
        }
      }
      & > .toggle {
        margin-left: 0.5rem;
      }
    }
    
  }
}
