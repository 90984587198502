#porto-product-page {
    &.porto-page-ctn {
        background-color: $lightgrey-bg;
        >ion-content {
            // --ion-background-color: #dadada;
            // background-color:red;
            // padding: 0;
            padding: 0 1rem;
        }
        // padding: 0rem;
       .content{
        padding: 0 1rem;

       }
        .slide-container {
            display: flex;
            flex-direction: column;
            flex: 0.5;
            .indicators {
                padding: 0;
            }
        }

        .indicator {
            width: 0.5rem;
            height: 0.5rem;
            margin: 0 0.25rem;
            border-radius: 10rem;
            background-color: rgba(0, 0, 0, 0.1);
            &.active {
                width: 1.125rem;
                height: 0.5rem;
                margin: 0 0.25rem 0 0;
                border-radius: 1rem;
                background-color: #000000;
            }
        }
        .product-detail-ctn{
            display: flex;
            .product-detail-txt{
                padding-left: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .top-detail-ctn{
                    display: flex;
                    flex-direction: column;

                }
                .product-title{
                    font-size: 1rem;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.8px;
                    color: #000000;
                }
                .product-subtitle{
                    font-size: 0.875rem;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.56px;
                    color: $grey;
    
                }
                .product-price{
                    font-size: 1.5rem;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 1.2px;
                    color: #000000;
                }

            }

            
        }
        .shop-profile-container {
            // width: 23.438rem;
            // height: 100%;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // margin-top: -0.5rem;
            ion-img {
                // height: 100%;
                object-fit: cover;
            }
            div.images-wrap > div:not(.active) {
                // border: solid 0.5px $red;
                // margin-left: -4rem;
            }
            div.images-wrap > div.active {
                // border: solid 0.5px $red;
                // margin-right: -4rem;
            }
            .collection-img-container {
                // background: bisque;
                // border: 1px solid $border;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .collection-header {
                    font-size: $medium;
                    font-weight: 700;
                    margin: 0.75rem 0;
                }
                .collection-img {
                    border-radius: 0.75rem;
                    object-fit: cover; 
                    height: 11.875rem;
                    width: 11.875rem;
                }
            }
        }

        .menu-container {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            // height: 100%;
            height: fit-content;
            padding-bottom: 2rem;
            // margin-top: -1.5rem;
            position: relative;
            z-index: 3;
        }

        .order-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            .btn-container {
                // margin: 0;
            }
            .cart-add {
                width: 4.938rem;
                height: 2.813rem;
                margin: 0 1.25rem 0 0;
                padding: 0.438rem 1.438rem 0.375rem 1.5rem;
                border-radius: 10rem;
                border: solid 0.5px $border;
                background-color: #ffffff;              
                .cart-icon {
                    width: 1.75rem;
                }
            }
        }

        .share-shop-container {
            width: 5rem;
            height: 1.5rem;
            padding: 0.188rem 1.125rem 0.188rem 1.125rem;
            border-radius: 1rem;
            border: solid 0.5px #f1be30;
            background-color: rgba(241, 190, 48, 0.1);
            color: #f1be30;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .share-icon {
                // font-size: 5rem;  
                margin-right: 0.5rem;
            }
            p {
                font-size: $small;
                margin: 0;
            }
        }
        .profile-container {
            font-size: $small;
            height: 2.5rem;
            align-items: center;
            justify-content: center;
            align-content: center;
            display: flex;
            margin-right: 1rem;
            .shop-img {
                 // border: 1px solid $border;
                height: 100%;
                width: 2.5rem;
                background-size: contain;
                background-repeat: no-repeat;
                align-self: center;
                border-radius: 10rem;
                
                &-blank {
                    background-color: $profile-bg;
                    height: 100%;
                    width: 2.5rem;
                    border-radius: 10rem;
                }
            }
        }
        .all-content-ctn{
            // background-color: #f1be30;
            padding: 0 1rem 6.5rem 1rem ; 
        }
        .page-navigation{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.7rem;
            
        }
        .header-bg{
            height:5rem;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .center-justi{
            display: flex;
            justify-content: center;
            .banner-pic{
                margin-top: 1rem;
                box-shadow:4px 5px 0px 0px #c9e8fc;
                border-radius: 1rem;
            }
            .main-txt{
                font-size: 1.25rem;
                font-weight: 600;
                letter-spacing: 1px;
                margin-top: 2rem;
            }
        }
        .shop-list-ctn{
            padding: 0.5rem 0;
            // background-color: aquamarine;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            .shop-list-item{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;    
                .pic{
                    height: auto;
                    width: auto;
                } 
                .shop-txt{
                    font-size: 0.75rem;
                    font-weight: normal;
                    letter-spacing: 0.48px;
                    color: #000000;
                }           
            }
            
        }
        .shop-detail-top{
            padding-left: 0.5rem;
            margin-top: 1rem;
            margin-bottom: 1.5rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .shop-pic{
                width: 2.4rem;
                height: 2.4rem;

            }
            .shop-title-txt{
                font-size: 1.25rem;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 1px;
                margin-left: 1rem;
            }
        }
        .categories-title{
            margin: 1rem 0;
            .sup-title-txt{
                font-size: 1rem;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.8px;
            }
        }
      

        .filter-ctn{
            display: flex;
            justify-content: flex-end;
            .filter-style{
                padding: 0 1.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 9.875rem;
                height: 2.25rem;
                color: #e2491e;               
                border-radius: 20px;
                background-color: rgba(223, 93, 57, 0.1);
            }
            .filter-txt{
                font-size: 0.875rem;
                font-weight: 500;
                letter-spacing: 0.7px;
                margin-right: 0.5rem;
            }
        }
        
        .product-scroll{
            width: fit-content;
            // margin-left: -1rem;
            // margin-bottom: 2rem;
            padding-right: 1rem;
            // height: 6.75rem;
            display: flex;
            flex: 1;
            flex-direction: row;
            // width: fit-content;
            white-space: nowrap;
            overflow-x: auto;
            max-width: calc(100% + 2rem);
            &:after {
              content: "";
              flex: auto;
            }

            .product-card-item{
                // background-color: #f1be30;
                display: flex;
                flex-direction: column;
                padding: 0 0.4rem;
             
               
             
                
            }
        }
        ion-item.radio-badge-product {
            border-radius: 10rem;
            min-width: 5rem;
            width: fit-content;
            height: 1.75rem;
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0.125rem 0;
            border: solid 0.5px rgba(0, 0, 0, 0.2);
            background-color: #ffffff;
            margin-right: 0.5rem;
            margin-top: 0.25rem;
            margin-bottom: 0.25rem;
          
            &::part(native) {
              background: transparent;  
              padding-left: 0;
            }
            ion-radio {
              width: 0;
              margin: 0.5rem;
              &::part(mark) {
                display: none;
              }
              &::part(container) {
                border-color: transparent;
                border: none;
                border-width: 0;
              }
            }
            .radio-badge-label-product {
              margin: 0;
              font-size: 0.875rem;
              font-weight: normal;
              letter-spacing: 0.56px;
              color: #828282;
            }
            &.item-radio-checked {
              // background-color: #000000;
              background-color: rgba(223, 93, 57, 0.2);
              color: #e2491e;
              font-weight: 800;
              border:none
            //   border: 1px black solid;
            }
          }
          .qty-ctn{
              display: flex;
              margin-top: 1rem;
            //   background-color: #f1be30;
              justify-content: space-between;
              align-items: center;
              height: 2rem;
              .sup-title-txt1{
                font-size: 1rem;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.8px;
            }
              .qty-right{
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  .add-remove-btn{
                    width: 1.75rem;
                    height: 1.75rem;
                    background-color: rgba(223, 93, 57, 0.2);
                    color: #e2491e;
                    border-radius: 3rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 1rem;
                }
                .qty-text{
                    font-size: 0.875rem;
                    font-weight: bold;
                    line-height: 1.43;
                    letter-spacing: 0.7px;
                }
              }
          }
          .text-area-ctn{
            //   background-color: #f1be30;
            //   padding: 0 1rem;
              .text-area{
              border: 0.5px solid rgba(0, 0, 0, 0.1);;
              border-radius: 0.6rem;
              padding: 0 0.2rem;
              }
          }
          .input-ctn{
            margin-top: 1.5rem;
            width: 100%;
            height: 3.125rem;
            border: solid 0.5px rgba(0, 0, 0, 0.1);
            border-radius: 1rem;
            --padding-start:1rem;
            font-size: 0.75rem;
          }

          .tab-bar-step2 {
            width: 100%;
            height: 5.75rem;
            margin: 2.875rem 0 0 0;
            padding: 1rem 1rem;
            // box-shadow: 0 1px 25px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.05);
            border: solid 0.3px #ffffff;
            background-color: #ffffff;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;

            display: flex;
            flex: 1;
            flex-direction: row;
            align-items: center;
            position: fixed;
            bottom: 0;
            z-index: 9999;
            .order-container{
                width: 100%;
            }
            .buy-btn{
                width: 14.813rem;
                height: 2.813rem;
                border-radius: 24px;
                background-color: #e2491e;
            }
            & div.btn-container {
                display: flex;
                // justify-content: flex-end;

                .buy-btn{
                    width: 14.813rem;
                    height: 2.813rem;
                    border-radius: 24px;
                    background-color: #e2491e;
                }
                // border: 1px solid rgba(0, 0, 0, 0.1);
            }
            }


    }
   
}