#shop-info {
    &.content-container {
        background-color: $lightgrey-bg;
        > ion-content {
            --ion-background-color: $lightgrey-bg;
        }
        padding: 0;
    }
    .btn-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        background-color: white;
        padding: 1.5rem 1rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        position: fixed;
        bottom: 0;
        z-index: 2;
        margin: 0;
        box-shadow: #00000017 0px 1px 25px 0px;

        .save-btn {
          flex: 0.48;
          border: 0.3px solid $black;
          height: 2.25rem;
          padding: 8px 7.7px 8.8px 7.7px;
          border-radius: 15rem;
          background-color: #ffffff;
          color: $black;

          &--primary {
            background-color: #000000;
            color: #ffffff;
          }
        }
    }
}