// @use 'abstracts' as *;

#segment {
  &.container {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 2.5rem;
    // border: 1px solid $border;
    align-items: center;

    .segment {
      background: #ffffff;
      border: 1px solid $border;
      border-radius: 10rem;
      font-size: $small;

      .segment-btn {
        // height: 1rem;
        // border: 1px solid $border;
        background: transparent;
        border-radius: 10rem;
        margin: 0;
      }
    }
    ion-segment-button {
      &::part(indicator) {
        padding: 0;
      }
      &::part(indicator-background) {
        border-radius: 10rem !important;
        background: $black !important;
      }
      &.segment-button-checked > ion-label {
        color: #ffffff;
      }
    }
  }
}
