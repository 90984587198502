// @use 'abstracts' as *;

#list-item {
  &.container {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 2.5rem;
    // border: 1px solid $border;
    color: $black;
    align-items: center;
    margin-bottom: 1rem;
    // background-color: #ffffff;
    
    & > .list-img-container {
      // border: 1px solid $border;
      font-size: $small;
      height: 2.5rem;
      align-items: center;
      justify-content: center;
      align-content: center;
      display: flex;
      margin-right: 1rem;

      .list-img {
        // border: 1px solid $border;
        height: 100%;
        width: 2.5rem;
        background-size: contain;
        background-repeat: no-repeat;
        align-self: center;
        border-radius: 10rem;
        &-blank {
          background-color: $profile-bg;
          height: 100%;
          width: 2.5rem;
          border-radius: 10rem;
        }
      }
    }

    & > .list-title-text {
      font-size: $small;
      flex: 0.65;
      justify-items: center;
    }

    & > .list-desc--container {
      // border: 1px solid $border;
      font-size: $normal;
      display: flex;
      flex: 0.35;
      height: 100%;
      text-align: right;
      justify-content: flex-end;
      align-items: center;
      
      & > .list-desc {
        font-size: $smaller;
        color: $grey;
        // margin-bottom: 0;
      }
    }
    
  }
}
