#shipping-status {
    &.content-container {
        background-color: $lightgrey-bg;
        > ion-content {
            // --ion-background-color: #dadada;
            background-color: $lightgrey-bg;
        }
        padding: 0rem;

        .slide-container {
            display: flex;
            flex-direction: column;
            flex: 0.5;
            .indicators {
                padding: 0;
            }
        }

        .indicator {
            width: 0.5rem;
            height: 0.5rem;
            margin: 0 0.25rem;
            border-radius: 10rem;
            background-color: rgba(0, 0, 0, 0.1);
            &.active {
                width: 1.125rem;
                height: 0.5rem;
                margin: 0 0.25rem 0 0;
                border-radius: 1rem;
                background-color: #000000;
            }
        }

        .shop-profile-container {
            // width: 23.438rem;
            height: 20rem;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // margin-top: -0.5rem;
            ion-img {
                // height: 100%;
                object-fit: cover;
            }
            // div.images-wrap > div:not(.active) {
            //     // border: solid 0.5px $red;
            //     // margin-left: -4rem;
            // }
            // div.images-wrap > div.active {
            //     // border: solid 0.5px $red;
            //     // margin-right: -4rem;
            // }
            .collection-img-container {
                // background: bisque;
                // border: 1px solid $border;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .collection-header {
                    font-size: $medium;
                    font-weight: 700;
                    margin: 0.75rem 0;
                }
                .collection-img {
                    border-radius: 0.75rem;
                    object-fit: cover; 
                    height: 11.875rem;
                    width: 11.875rem;
                }
            }
        }

        .menu-container {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            height: 100%;
            height: fit-content;
            padding-bottom: 2rem;
            // margin-top: -1.5rem;
            position: relative;
            // z-index: 3;
            // background-color: #f1be30;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-repeat: no-repeat;
            background-size: contain;
            
        }

        .shipping-pic{
            width: 5.4rem;
            // height: 4rem;
        }
        .title-text{
            font-size: medium;
            font-weight: 700;
        }
        .shipping-status{
            margin-top: 1rem;
            width:6.5rem ;
            height: 1.625rem;
            background-color: black;
            color: white;
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: small;
        }
        .tracking-number{
            margin-top: 1rem;
            width: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .shipping-meth-text{
            font-size: $small;
            color: #f1be30;
        }
        .tracking-btn{
            margin-top: 1rem;
            width:16.75rem ;
            height:2.5rem;
            background-color:rgba(255, 213, 98, 0.06);
            color: #f1be30;
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 0.5px #f1be30;  
        }

        

        .order-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            // .btn-container {
            //     // margin: 0;
            // }
            .cart-add {
                width: 4.938rem;
                height: 2.813rem;
                margin: 0 1.25rem 0 0;
                padding: 0.438rem 1.438rem 0.375rem 1.5rem;
                border-radius: 10rem;
                border: solid 0.5px $border;
                background-color: #ffffff;              
                .cart-icon {
                    width: 1.75rem;
                }
            }
        }

        .share-shop-container {
            width: 5rem;
            height: 1.5rem;
            padding: 0.188rem 1.125rem 0.188rem 1.125rem;
            border-radius: 1rem;
            border: solid 0.5px #f1be30;
            background-color: rgba(241, 190, 48, 0.1);
            color: #f1be30;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .share-icon {
                // font-size: 5rem;  
                margin-right: 0.5rem;
            }
            p {
                font-size: $small;
                margin: 0;
            }
        }
        .profile-container {
            font-size: $small;
            height: 2.5rem;
            align-items: center;
            justify-content: center;
            align-content: center;
            display: flex;
            margin-right: 1rem;
            .shop-img {
                 // border: 1px solid $border;
                height: 100%;
                width: 2.5rem;
                background-size: contain;
                background-repeat: no-repeat;
                align-self: center;
                border-radius: 10rem;
                
                &-blank {
                    background-color: $profile-bg;
                    height: 100%;
                    width: 2.5rem;
                    border-radius: 10rem;
                }
            }
        }
    }
}