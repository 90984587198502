// @use 'abstracts' as *;

#input-item {
  &.container {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 3.5rem;
    // border: 1px solid $border;
    color: $black;
    align-items: center;
    border-top: 0.5px solid $border;
    // padding-left: 0.75rem;
    &--multiple {
      flex-direction: column;
      height: fit-content;
    }

    & > .input-title-text {
      // border: 1px solid $border;
      font-size: $small;
      flex: 0.5;
      
      &--multiple {
        width: 100%;
      }
    }

    .input-desc {
      font-size: $smaller;
      color: $grey;
      margin-top: -0.75rem;
      // border: 1px solid $border;
    }

    & > .input-container {
      // border: 1px solid $border;
      font-size: $normal;
      display: flex;
      flex: 0.5;
      height: 100%;
      text-align: right;
      justify-content: flex-end;
      align-items: center;
      
      & > .input-placeholder {
        font-size: $small;
        color: $grey;
        margin-right: 0.2rem;
        // border: 1px solid $border;
      }
      & > .right-icon {
        // border: 1px solid $border;
        font-size: $medium;
        color: $black;

        &.editable {
          padding-bottom: 0.1rem;
          border-bottom: 1px solid $black;
        }
      }

      &--multiple {
        // border-bottom: 1px solid $red;
        flex: 1;
        width: 100%;
        text-align: left;
      }
    }

    
  }
}
