// @use 'abstracts' as *;

#search-bar {
  &.container {
    display: flex;
    flex: 1;
    flex-direction: row;
    // justify-content: center;
    height: 2.5rem;
    border: 1px solid $border;
    border-radius: 3rem;
    color: $black;
    align-items: center;
    background-color: #fff;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;

    .search-icon {
      font-size: $normal;
      color: $black;
      position: relative;
      z-index: 1;
      left: 0.75rem;
    }

    .searchbar {
      width: 100%;
      // border: 1px solid $black;
      padding-left: 0;
      padding-right: 0;
      height: 95%;

      &-input {
        font-size: $small;
        margin-left: -0.5rem;
      }

      > .searchbar-input-container {
        // border: 1px solid $border;
        border-radius: 3rem;

        > .searchbar-input {
          height: 100%;
          box-shadow: none;

          &.sc-ion-searchbar-ios {
            background-color: transparent;
            border-radius: 3rem;
            // border: 1px solid $red;
          }
        }
      }
    }

    .filter-icon-container {
      // border: 1px solid $black;
      .filter-icon {
        font-size: 1rem;
        color: $black;
        margin-left: 0.5rem;
        margin-right: 0.9rem;
      }
    }
  }
}
