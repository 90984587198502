// @use 'abstracts' as *;

#list-title {
  &.container {
    display: flex;
    flex: 0.8;
    flex-direction: row;
    // justify-content: center;
    height: 2.5rem;
    // border: 1px solid $black;
    color: $black;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;

    & > .list-title-icon--container {
      font-size: 1rem;
      line-height: 26px;
      display: flex;
      flex: 0.2;
      height: 100%;
      text-align: right;
      // border: 1px solid $black;
      justify-content: flex-end;
      align-items: center;
      
      & > .list-title-icon {
        // border: 0.14rem solid $black;
        border-radius: 0.3rem;
        
        &:not(:first-child):last-child {
          margin-left: 1rem;
          border: none;
          font-size: 1.4rem;
        }
      }
    }

    & > .list-title-text {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 26px;
      flex: 1;
    }
  }

  .container a {
    text-decoration: none;
  }
}
