#shipping-payment{
    &.container {
      display: flex;
      flex-direction: column;
      border: 0.5px solid #bdbdbd;
      border-radius: 16px;
      padding: 1rem;
      width: 100%;
      background-color: white;


      .payment-list-show{
        // border-bottom: 0.5px solid #ebebeb;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color:#f5f5f5 ;
        border-radius: 15px;
        height: 4rem;
        margin-top: 1rem;
        // padding-bottom: 0.5rem;

        .product-pic{
            width: 2.75rem;
            height: 2.75rem;
            // background-color: rgb(233, 233, 233);
            border-radius: 24px;
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 1.5rem;
            flex: 1;

        }
        .product-detail{
            // margin-left: 1rem;
            display: flex;
            flex-direction: column;
            // justify-items: center;
            justify-content: center;
            flex: 4;
        }
        .product-price{
            display: flex;
            flex-direction: column;
            align-items: flex-end;

        }


      }

      .payment-confirm-show{
        display: flex;
        flex-direction: row;
        align-items: center;
        // background-color:#f5f5f5 ;
        border-radius: 15px;
        height: 6rem;

        .slip-pic{
            width: 4rem;
            height: 4rem;
            // background-color: rgb(233, 233, 233);
            border-radius: 24px;
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 0.5rem;
            flex: 1;

        }
        .product-detail{
            // margin-left: 1rem;
            display: flex;
            flex-direction: column;
            // justify-items: center;
            justify-content: center;
            flex: 3;
        }
        .time-detail-row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 0.2rem;
            height: 2rem;    
        }
        .time-show{
            border: 0.5px solid rgb(231, 231, 231);
            border-radius: 7px;
            width: 5.5rem;        
            font-size: $small;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .normal-text{
            font-size: small;
        }
       

      }
      .payment-type{
        font-size: 0.875rem;
        font-weight: 500;
        // color: grey;
    }
        .header {
            // margin: 1rem 0;
            font-size: 0.875rem;
            font-weight: bold;
        }
        .sub-header {
            margin: 1rem 0;
            font-size: $small;
            font-weight: bold;
        }
        .ava-ctn {
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem;
        }
        .div-style {
            margin-top: 0.5rem;
        }
        .name-ctn {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-left: 0.5rem;
            > span {
                &:first-child {
                    font-size: $small;
                }
                &:nth-child(2) {
                    font-size: $smaller;
                }
            }
        }
        .desc-text {
            font-size: $smaller;
            color: $grey;
        }
        .bt-text {
            font-size: $small;
            color: $black;
            margin: 0.25rem 0;
            &--secondary {
                color: $lightgrey-text;
            }
        }
        .desc-ctn {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
        }
        .print-btn {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
     
    }
  }
  