#shipping-product{
    &.container {
      display: flex;
      flex-direction: column;
      border: 0.5px solid #bdbdbd;
      border-radius: 16px;
      padding: 1rem;
      width: 100%;
      background-color: white;


      .pruduct-list-show{
        border-bottom: 0.5px solid #ebebeb;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 0.5rem;

        .product-pic{
            width: 2.75rem;
            height: 2.75rem;
            background-color: rgb(233, 233, 233);
            border-radius: 8px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .product-detail{
            margin-left: 1rem;
            display: flex;
            flex-direction: column;
            flex: 2;
        }
        .product-price{
            display: flex;
            flex-direction: column;
            align-items: flex-end;

        }


      }
        .header {
            // margin: 1rem 0;
            font-size: 0.875rem;
            font-weight: bold;
        }
        .sub-header {
            margin: 1rem 0;
            font-size: $small;
            font-weight: bold;
        }
        .ava-ctn {
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem;
        }
        .div-style {
            margin-top: 0.5rem;
        }
        .name-ctn {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-left: 0.5rem;
            > span {
                &:first-child {
                    font-size: $small;
                }
                &:nth-child(2) {
                    font-size: $smaller;
                }
            }
        }
        .desc-text {
            font-size: $smaller;
            color: $grey;
        }
        .bt-text {
            font-size: $small;
            color: $black;
            margin: 0.25rem 0;
            &--secondary {
                color: $lightgrey-text;
            }
        }
        .desc-ctn {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
        }
        .print-btn {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
     
    }
  }
  