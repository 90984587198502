#payment {
    &.content-container {
        background-color: #ffffff;
        > ion-content {
            --ion-background-color: #ffffff;
        }
        padding: 1rem 0rem;
    }
    .shop-profile-container {
        // border: 1px solid $border;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        // margin-bottom: 1rem;
        background-color: transparent;

        .profile-img {
            width: 5rem;
        }

        .shop-name-text {
            font-size: $medium;
            font-weight: bold;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        .shop-code-text {
            font-size: $small;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}