#promotion {
  &.container {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $black;
      min-width: 17.325rem;
      margin-left: 0.75rem;
      margin-bottom: 0.5rem;
      border-radius: 16px;
      width: 15.375rem;
      height: 6.625rem;
      object-fit: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      padding: 1rem 1.5rem;
      // border: solid 1px $border;
      // background-color: rgba($color: #e3f6ff, $alpha: 0.2);
      &.full-width {
        width: 100%;
        height: 4rem;
        padding-top: 0.25rem;
        margin-left: 0;
        & > .icon-container {
          margin-top: 0.2rem;
          flex: 0.15;
        }
        & > .content {
          flex: 0.85;
          strong {
            P {
              margin-left: 1rem;
              font-weight: 500;
              display: inline;
            }
          }
        }
       
      }
      &.md {
        & > .icon-container {
          margin-top: 0.2rem;
          flex: 0.2;
        }
        & > .content {
          flex: 0.8;
        }
        height: 5.75rem;
      }
      &.lg {
        height: 5.75rem;
      }
      
    strong {
      font-size: $light;
    }

    .divider--line {
      // border-color: $border;
      margin: 0.4rem 0;
    }
    
    p {
      font-size: $smaller;
      margin: 0;
      color: $black;
      align-items: center;
      display: flex;
      &.date {
        font-size: $smaller;
        color: $grey;
        margin: 0.5rem 0;
      }
    }
    
    a {
      text-decoration: none;
    }

    .time-icon {
      font-size: 0.875rem;
      margin-right: 0.25rem;
    }
    
    .content {
      flex: 0.75;
      padding: 0 1.5rem 0.25rem 1.5rem;
      margin-top: -0.2rem;
      width: 100%;
      flex: 1;
    }

    .arrow-container {
      width: 2rem;
      height: 2rem;
      background-color: $grey-bg;
      border-radius: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .arrow-icon {
        color: $grey;
        font-size: 1rem;
      }
    }

    .progressbar {
      background-color: #f1f1f1;
      border-radius: 1rem;
      &::part(progress) {
        background-color: #000000;
      }
    }
    .get-code-container {
      background-color: #000;
      padding: 0.15rem 0.5rem;
      border-radius: 10rem;
      color: white;
      font-size: $small;
      min-width: 3.625rem;
      min-height: 1.125rem;
      margin-left: 1rem;
    }
  }
}
